import React from 'react';
import HomeFourHowWorks from '../../HomeFour/HomeFourHowWorks/HomeFourHowWorks';

const HomeFiveHowWorks = () => {
   return (
      <>
         <HomeFourHowWorks/>
      </>
   );
};

export default HomeFiveHowWorks;