
const priceListData = [
    { "label": "Արյան ընդհանուր քննություն", "value": 1500 },
    { "label": "Լեյկոֆորմուլայի որոշում", "value": 1500 },
    { "label": "Մակարդելության որոշում", "value": 1000 },
    { "label": "Տրոմբոցիտների հաշվում", "value": 1000 },
    { "label": "Մեզի ընդհանուր քննություն", "value": 1500 },
    { "label": "Քսուքի հետազոտություն", "value": 2000 },
    { "label": "Լեղապիգմենտների որոշ մեզի մեջ", "value": 1000 },
    { "label": "Ճիճվակրության հայտնաբերում կղանքում", "value": 1000 },
    { "label": "Արյան տիտրի որոշում/որակական/", "value": 1000 },
    { "label": "Ցիտոլոգիական հետազոտություն", "value": 3000 },
    { "label": "Հեմոգլոբինի որոշում", "value": 1000 },
    { "label": "Սրտային տրոպոնինի որոշում", "value": 5000 },
    { "label": "Հեպատիտ <<C>>", "value": 4000 },
    { "label": "HBS-անտիգեն", "value": 3000 },
    { "label": "Գլյուկոզիլացված հեմոգլոբին", "value": 4000 },
    { "label": "Հելիկոբակտեր պիլորի", "value": 4000 },
    { "label": "Գլյուկոզա արյան մեջ", "value": 1000 },
    { "label": "Ալբումին", "value": 1000 },
    { "label": "Տրիգլիցերիդներ", "value": 1000 },
    { "label": "Ընդհանուր լիպիդներ", "value": 1000 },
    { "label": "Խոլեստերին", "value": 1000 },
    { "label": "Միզաթթու", "value": 1000 },
    { "label": "Ամիլազա", "value": 1500 },
    { "label": "Հիմնային ֆոսֆատազա", "value": 1500 },
    { "label": "Լյարդի ֆունկցիոնալ փորձեր", "value": 3000 },
    { "label": "Բիլիռուբին", "value": 1000 },
    { "label": "Ընդհանուր սպիտակուց", "value": 1000 },
    { "label": "Ասպարտատամինոտրանսֆերազա /AST/", "value": 1000 },
    { "label": "Ալանինամինոտրանսֆերազա /ALT/", "value": 1000 },
    { "label": "Ինսուլինի հետազոտություն", "value": 2500 },
    { "label": "Երիկամային փորձ/կրեատին,միզանյութ/", "value": 2000 },
    { "label": "Կրեատինին", "value": 1000 },
    { "label": "Միզանյութ", "value": 1000 },
    { "label": "Կոագուլոգրա/պրոտրոմբին,ֆիբրինոգեն/", "value": 2000 },
    { "label": "Ֆիբրինոգեն", "value": 1000 },
    { "label": "Պրոտրոմբին", "value": 1000 },
    { "label": "Կալցիում", "value": 1000 },
    { "label": "Մագնեզիում", "value": 1000 },
    { "label": "Կալիում", "value": 1000 },
    { "label": "RH/արյան ռեզուս/", "value": 1000 },
    { "label": "AB O/արյան խումբ/", "value": 1000 },
    { "label": "RH/AB O/արյան ռեզուս,խումբ/", "value": 2000 },
    { "label": "MRP/սլայդ-տեստ/", "value": 1000 },
    { "label": "CRP/սլայդ-տեստ/", "value": 1000 },
    { "label": "RF/սլայդ-տեստ/", "value": 1000 },
    { "label": "ACLO", "value": 1000 },
    { "label": "Կղանքի բակ հետազոտություն", "value": 3000 },
    { "label": "Կղանքի դիզբակտերիոզ", "value": 3500 },
    { "label": "Մեզի բակ հետազոտություն", "value": 3000 },
    { "label": "Անտիբիոտիկազգայունություն", "value": 3000 },
    { "label": "Արտադրության բակ հետազոտություն", "value": 3000 },
    { "label": "Բկանցքի քսուքի հետազոտություն", "value": 3000 },
    { "label": "13/18 ռենտգեն հետազոտություն", "value": 3500 },
    { "label": "18/24 ռենտգեն հետազոտություն", "value": 4000 },
    { "label": "24/30 ռենտգեն հետազոտություն", "value": 4500 },
    { "label": "30/40 ռենտգեն հետազոտություն", "value": 7000 },
    { "label": "ԷՍԳ", "value": 2000 },
    { "label": "Միզասեռական համակարգի օրգանների սոնոգրաֆիա/երիկամ", "value": 5000 },
    { "label": "Աղեստամոքսային համակարգի օրգանների սոնոգրաֆիա/լյարդ,լեղապարկ", "value": 5000 },
    { "label": "Փոքր կոնքի ՈՒՁՀ", "value": 5000 },
    { "label": "Վահանաձև գեղձի ՈՒՁՀ/սոնոգրաֆիա/", "value": 5000 },
    { "label": "Կրծքագեղձի ՈՒՁՀ/սոնոգրաֆիա/", "value": 5000 },
    { "label": "Վահանաձև գեղձի և կրծքագեղձի ՈՒՁՀ/սոնոգրաֆիա/", "value": 8000 },
    { "label": "Մագիստրալ անոթների դուպլեքս հետազոտություն", "value": 8000 },
    { "label": "Փափուկ հյուսվածքների ՈՒՁՀ/սոնոգրաֆիա/", "value": 5000 },
    { "label": "Ավշային հանգույցների ՈՒՁՀ/սոնոգրաֆիա/", "value": 5000 },
    { "label": "Բժիշկ-մասնագետի խորհրդատվություն", "value": 3000 },
    { "label": "Բժիշկ-մասն.խորհրդատվություն և բուժման նշանակում", "value": 5000 },
    { "label": "Վերքի վիրաբուժական մշակում/առանց կար դնելու/", "value": 5000 },
    { "label": "Վերքի առաջնային վիրաբուժական մշակում՝ կար դնել", "value": 7000 },
    { "label": "Փոքր ծավալի վիրահատություններ", "value": 10000 },
    { "label": "Միջին ծավալի վիրահատություններ", "value": 15000 },
    { "label": "Վիրակապություն", "value": 3000 },
    { "label": "Գիպսակապի դնում/առանց նյութերի/", "value": 3000 },
    { "label": "Գիպսակապի դնում/ֆալանգներ,նախադաս.և նախաթաթ. ոսկոր/", "value": 8000 },
    { "label": "Գիպսակապի դնում/նախաբազկի և սրունքի ոսկորներ", "value": 12000 },
    { "label": "Գիպսակապի դնում/բազուկոսկր", "value": 16000 },
    { "label": "Գիպսակապի դնում/ազդոսկրի կտրվածք", "value": 5000 },
    { "label": "Երկաթի որոշում արյան մեջ", "value": 1000 },
    { "label": "Թաքնված արյան որոշում կղանքում", "value": 4000 },
    { "label": "Ականջի խցանի և օտար մարմնի հեռացում", "value": 2000 },
    { "label": "Հայմորիտի ատամնաբուժ.սինուսիտ", "value": 5000 },
    { "label": "Տեղային անզգայացման դեղամիջոցներ", "value": 1000 },
    { "label": "Լաբորատորայի փորձարկում", "value": 1500 }
  ]
  
export default priceListData;