import React from 'react';
import HomeFourFiveNavBar from '../../../components/Shared/HomeFourFiveNavBar/HomeFourFiveNavBar';


const HomeFourNavBar = () => {
   return (
      <>
         <HomeFourFiveNavBar/>
      </>
   );
};

export default HomeFourNavBar;